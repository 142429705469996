@media (min-width: 600px) {
  .unique-design {
    background-image: url("./unique-design.png");
    display: flex;
    flex-direction: row;
    height: 600px;
    margin-top: 150px;
    padding-left: 230px;
  }

  .unique-design-img {
    margin-top: 90px;
    height: 700px;
  }

  .unique-design-right {
    margin-top: 125px;
    padding-left: 230px;
  }
}
@media (max-width: 600px) {
  .unique-design {
    background-image: url("./unique-design.png");
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 80px;
    gap: 20px;
  }

  .unique-design-img {
    margin-top: 20px;
    height: 400px;
  }

  .unique-design-right {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .unique-design-desc {
    margin-bottom: 40px;
  }
}
