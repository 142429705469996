@media (min-width: 600px) {
  .protect-app {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    padding-left: 120px;
    gap: 350px;
  }

  .protect-app-img {
    height: 649px;
  }

  .protect-app-right {
    margin-top: 80px;
  }
}

@media (max-width: 600px) {
  .protect-app {
    align-items: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .protect-app-right {
    margin-top: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 5%;
  }

  .protect-app-img {
    height: 403px;
  }
}
