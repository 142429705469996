@media (min-width: 1200px) {
  .desktop-app {
    height: 730px;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: row;
    gap: 130px;
    padding-top: 132px;
  }

  .featured-image {
    width: 690px;
    height: 595px;
  }

  .featured-title {
    color: white;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    width: 388px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .featured-description {
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #9a96aa;
    width: 400px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .list-item {
    width: 400px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .desktop-app {
    height: 730px;
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
    gap: 90px;
    padding-top: 132px;
  }

  .featured-image {
    width: 440px;
    height: 400px;
  }

  .featured-title {
    color: white;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    width: 280px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .featured-description {
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #9a96aa;
    width: 340px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .list-item {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .desktop-app {
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 20px;
    padding-bottom: 25px;
  }

  .featured-image {
    width: 330px;
    height: 278.58px;
  }

  .featured-title {
    color: white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    width: 221px;
    text-align: left;
    margin-bottom: 40px;
  }

  .featured-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #bdbac9;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .list-item {
    width: 280px;
  }
}
