@media (min-width: 600px) and (max-width: 1200px) {
  .get-demo-container {
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .get-demo {
    background-image: url(./get-demo.png);
    width: 70%;
    border-radius: 10px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  .get-demo-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: white;
  }
}

@media (min-width: 1200px) {
  .get-demo-container {
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .get-demo {
    background-image: url(./get-demo.png);
    width: 70%;
    border-radius: 10px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  .get-demo-title {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: white;
  }
}

@media (max-width: 600px) {
  .get-demo-container {
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .get-demo {
    background-image: url(./get-demo-mobile.png);
    width: 100%;
    height: 208px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  .get-demo-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  }
}
