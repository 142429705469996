@media (min-width: 600px) {
  .navigation {
    flex-direction: row;
    display: flex;
    color: white;
    justify-content: space-between;
    gap: 54px;
  }

  .navigation-item {
    color: #797588;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    cursor: pointer;
  }

  .navigation-item.selected {
    color: white;
  }
}

@media (max-width: 600px) {
  .navigation {
    flex-direction: row;
    display: none;
    color: white;
    justify-content: space-between;
    gap: 54px;
  }
}
