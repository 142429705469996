@media (min-width: 600px) {
  .list-item-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -2%;
    color: white;
  }

  .list-item-description {
    font-weight: 400;
    color: white;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -2%;
  }
}

@media (max-width: 600px) {
  .list-item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: white;
  }

  .list-item-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.02em;
    letter-spacing: -2%;
    color: white;
  }
}
