@media (min-width: 600px) {
  .live-classroom {
    margin-top: 160px;
    display: flex;
    flex-direction: row;
    gap: 250px;
  }

  .live-classroom-img {
    margin-top: 50px;
    height: 500px;
  }

  .live-classroom-left {
    margin-top: 70px;
    padding-left: 230px;
  }
}

@media (max-width: 600px) {
  .live-classroom {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .live-classroom-img {
    height: 300px;
  }

  .live-classroom-left {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
