@media (min-width: 1200px) {
  .feature-image-container {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #504c54;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -2%;
    color: #d0d0d0;
    width: 303px;
    text-align: left;
  }

  .feature-image {
    width: 19.1px;
    height: 19.1px;
  }

  .player-feature {
    display: flex;
    flex-direction: column;
    gap: 17.5px;
  }

  .players-image {
    width: 600.52px;
    height: 430.93px;
  }
}

@media (min-width: 600px) {
  .feature-image-container {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #504c54;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -2%;
    color: #d0d0d0;
    width: 200px;
    text-align: left;
  }

  .feature-image {
    width: 19.1px;
    height: 19.1px;
  }

  .player-feature {
    display: flex;
    flex-direction: column;
    gap: 17.5px;
  }

  .players-image {
    width: 450.52px;
    height: 323.93px;
  }
}

@media (max-width: 600px) {
  .feature-image-container {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #504c54;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #d0d0d0;
    width: 140px;
    text-align: left;
  }

  .feature-image {
    width: 19.1px;
    height: 19.1px;
  }

  .player-feature {
    display: flex;
    flex-direction: column;
    gap: 17.5px;
  }

  .players-image {
    width: 335px;
    height: 238px;
  }
}
