@media (min-width: 600px) {
  .feature-button-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
  }

  .feature-button-image {
    width: 50px;
    height: 40px;
  }

  .feature-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 350px;
    height: 90px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 70px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
  }

  .feature-buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    gap: 50px;
  }
}

@media (max-width: 600px) {
  .feature-button-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.02em;
  }

  .feature-button-image {
    width: 35px;
    height: 35px;
  }

  .feature-button-container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 75%;
    height: 60px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 70px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
  }

  .feature-buttons-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 18px;
  }
}
