@media (min-width: 600px) {
  .sell-courses {
    display: flex;
    flex-direction: row;
    padding-left: 120px;
    gap: 280px;
  }

  .sell-courses-img {
    height: 649px;
  }

  .sell-courses-right {
    margin-top: 200px;
  }
}

@media (max-width: 600px) {
  .sell-courses {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    align-items: center;
  }

  .sell-courses-img {
    height: 350px;
  }

  .sell-courses-right {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
