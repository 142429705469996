@media (min-width: 600px) {
  .app-features {
    margin-top: 90px;
    display: flex;
    flex-direction: row;
    gap: 250px;
  }

  .app-features-img {
    width: 650px;
  }

  .app-features-left {
    margin-top: 40px;
    padding-left: 230px;
  }
}

@media (max-width: 600px) {
  .app-features {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .app-features-img {
    width: 100%;
  }

  .app-features-left {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
}
