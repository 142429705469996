@media (min-width: 1200px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
    text-align: left;
  }

  .footer-logo-section {
    display: flex;
    flex-direction: column;
    color: white;
    width: 921px;
    align-items: center;
    gap: 16px;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 16px;
  }

  .footer-description {
    width: 300px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .footer-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    cursor: pointer;
  }

  .footer-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
  }

  .footer {
    display: flex;
    flex-direction: column;
    background: #08051b;
    height: 330px;
    justify-content: space-between;
  }

  .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
    padding: 10px;
    background-color: #110b26;
  }

  .footer-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
    text-align: left;
  }

  .footer-logo-section {
    display: flex;
    flex-direction: column;
    color: white;
    width: 550px;
    align-items: center;
    gap: 16px;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 16px;
  }

  .footer-description {
    width: 300px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .footer-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    cursor: pointer;
  }

  .footer-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
  }

  .footer {
    display: flex;
    flex-direction: column;
    background: #08051b;
    height: 330px;
    justify-content: space-between;
  }

  .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
    padding: 10px;
    background-color: #110b26;
  }

  .footer-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }
}

@media (max-width: 600px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    gap: 50px;
    margin-top: 60px;
    text-align: left;
  }

  .footer-logo-section {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: left;
    gap: 16px;
    align-self: flex-start;
    padding-left: 20px;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 16px;
  }

  .footer-description {
    width: 300px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .footer-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    cursor: pointer;
  }

  .footer-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
  }

  .footer {
    display: flex;
    flex-direction: column;
    background: #08051b;
    justify-content: space-between;
  }

  .copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
    padding: 10px;
  }

  .footer-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
    align-items: flex-start;
    padding: 20px;
  }
}
