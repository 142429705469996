@media (min-width: 600px) and (max-width: 1200px) {
  .delivery-cloud {
    background-image: url("./images/video-delivery-bg.png");
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: row;
    gap: 160px;
    height: 760px;
    padding-top: 100px;
    max-width: 100%;
    overflow: hidden;
  }

  .cloud-featured-image {
    width: 440px;
    height: 400px;
  }

  .cloud-featured-title {
    color: white;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    width: 400px;
    text-align: left;
    margin-bottom: 40px;
  }

  .cloud-list-item {
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .delivery-cloud {
    background-image: url("./images/video-delivery-bg.png");
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: row;
    gap: 130px;
    height: 760px;
    padding-top: 100px;
  }

  .cloud-featured-image {
    width: 655px;
    height: 595px;
  }

  .cloud-featured-title {
    color: white;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    width: 388px;
    text-align: left;
    margin-bottom: 40px;
  }

  .cloud-list-item {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .delivery-cloud {
    background-image: url("./images/video-delivery-bg.png");
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    padding-top: 15px;
    padding-bottom: 35px;
  }

  .cloud-featured-image {
    width: 320.34px;
    height: 339.29px;
    display: block;
  }

  .cloud-featured-title {
    color: white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    width: 221px;
    text-align: left;
    margin-bottom: 40px;
  }

  .cloud-list-item {
    width: 280px;
  }
}
