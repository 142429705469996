@media (min-width: 600px) {
  .banner {
    background-image: url("./images/hero-header.svg");
    height: 940px;
    display: flex;
    flex-direction: column;
    color: white;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

@media (max-width: 600px) {
  .banner {
    background-image: url("./images/hero-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 814px;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
