@media (min-width: 600px) {
  .header {
    z-index: 100;
    flex-direction: row;
    display: flex;
    color: white;
    justify-content: space-between;
    padding-left: 140px;
    padding-right: 140px;
    padding-top: 38px;
  }
}

@media (min-width: 1200px) {
  .header {
    z-index: 100;
    flex-direction: row;
    display: flex;
    color: white;
    justify-content: space-between;
    padding-left: 240px;
    padding-right: 220px;
    padding-top: 38px;
  }
}

@media (max-width: 600px) {
  .header {
    z-index: 100;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 38px;
    align-self: center;
  }
}
