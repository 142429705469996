@media (min-width: 600px) {
  .multi-teacher {
    background-image: url("./multi-teacher.png");
    display: flex;
    flex-direction: row-reverse;
    height: 600px;
    margin-top: 150px;
    padding-left: 230px;
  }

  .multi-teacher-img {
    margin-top: 90px;
    height: 600px;
  }

  .multi-teacher-right {
    padding-left: 230px;
  }

  .multi-teacher-list-item {
    width: 400px;
  }

  .multi-featured-title {
    color: white;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -0.03em;
    width: 500px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .multi-teacher {
    background-image: url("./multi-teacher.png");
    display: flex;
    flex-direction: column-reverse;
    height: 600px;
    margin-top: 100px;
  }

  .multi-teacher-img {
    margin-top: 90px;
    width: 100%;
  }

  .multi-teacher-right {
    margin-left: 50px;
  }

  .multi-teacher-list-item {
    width: 90%;
  }

  .multi-featured-title {
    color: white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    width: 80%;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .multi-teacher-features {
    padding-left: 5%;
  }
}
