@media (min-width: 600px) and (max-width: 1200px) {
  .players {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    text-align: center;
  }
  .offering {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -3%;
    color: #ffa319;
    margin-top: 150px;
    align-self: center;
  }
  .heading {
    margin-top: 91px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    align-items: center;
  }
  .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -3%;
    color: white;
  }

  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -3%;
    color: #797588;
    width: 548px;
  }

  .player-features {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 140px;
    padding-right: 140px;
    gap: 20px;
    margin-top: 90px;
  }

  .player-features-mobile {
    display: none;
  }

  .feature-section {
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .players {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    text-align: center;
  }
  .offering {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -3%;
    color: #ffa319;
    margin-top: 150px;
    align-self: center;
  }
  .heading {
    margin-top: 91px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    align-items: center;
  }
  .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -3%;
    color: white;
  }

  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -3%;
    color: #797588;
    width: 548px;
  }

  .player-features {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 280px;
    padding-right: 280px;
    gap: 20px;
    margin-top: 90px;
  }

  .player-features-mobile {
    display: none;
  }

  .feature-section {
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .players {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
  }
  .offering {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #ffa319;
    margin-top: 98px;
    align-self: center;
  }

  .heading {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
  }

  .title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: white;
    width: 305px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #797588;
    width: 315px;
  }

  .player-features {
    display: none;
  }

  .player-features-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 80px;
  }

  .feature-section {
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
  }

  .non-image-features {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    gap: 50px;
  }

  .feature-section-mobile {
    width: 140px;
  }
}
