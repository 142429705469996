@media (min-width: 600px) {
  .education-banner-bg {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 1136px;
    left: -134px;
    top: -358px;
    background: linear-gradient(180deg, #3315e8 0%, #fc8090 100%);
    opacity: 0.15;
    filter: blur(200px);
  }

  .education-banner {
    display: flex;
    flex-direction: row;
    margin-top: 135px;
    padding-left: 240px;
    padding-right: 240px;
    align-items: flex-start;
  }

  .education-banner-left-section {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 50px;
    text-align: left;
  }

  .education-banner-title {
    width: 708px;
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: white;
  }

  .education-banner-description {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: white;
    width: 446px;
  }

  .education-header-img {
    width: 572px;
    height: 673px;
  }
}

@media (max-width: 600px) {
  .education-banner-bg {
    position: absolute;
    width: 100%;
    height: 812px;
    left: 0px;
    top: 0px;
    background: linear-gradient(180deg, #3315e8 0%, #fc8090 100%);
    opacity: 0.2;
    filter: blur(200px);
  }

  .education-banner {
    display: flex;
    flex-direction: column;
    margin-top: 38px;
    gap: 56px;
    align-items: center;
  }

  .education-banner-left-section {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 50px;
    align-items: center;
  }

  .education-banner-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    width: 80%;
    text-align: center;
    letter-spacing: -0.03em;
    color: white;
  }

  .education-banner-description {
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
    width: 335px;
  }

  .education-header-img {
    height: 424px;
  }
}
