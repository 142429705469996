@media (min-width: 1200px) {
  .banner-title {
    margin-top: 170px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .banner-title-text {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -3%;
  }

  .sub-title {
    font-size: 20px;
    color: #d0d0d0;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -2%;
    width: 768px;
    align-self: center;
    text-align: center;
  }
}

@media (min-width: 600px) {
  .banner-title {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .banner-title-text {
    font-size: 50px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: -3%;
  }

  .sub-title {
    font-size: 20px;
    color: #d0d0d0;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -2%;
    width: 600px;
    align-self: center;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .banner-title {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .banner-title-text {
    width: 247px;
    align-self: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.03em;
  }

  .sub-title {
    width: 335px;
    color: #d0d0d0;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.01em;
  }
}
