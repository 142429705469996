@media (min-width: 600px) {
  .manage-app {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    margin-top: 100px;
    gap: 24px;
    margin-bottom: 100px;
  }

  .manage-app-img {
    height: 700px;
    width: 1122px;
  }

  .manage-app-title {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.03em;
    width: 780px;
  }

  .manage-app-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    width: 1031px;
  }
}

@media (max-width: 600px) {
  .manage-app {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    margin-top: 100px;
    gap: 24px;
    margin-bottom: 100px;
  }

  .manage-app-img {
    width: 335px;
  }

  .manage-app-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: center;
    width: 300px;
  }

  .manage-app-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    width: 340px;
  }
}
